import moment from 'moment-timezone';
import React, { useCallback, useEffect, useState } from 'react';
import Barcode from 'react-barcode';
import { Button, Stack } from 'react-bootstrap';
import { MdInventory2 } from 'react-icons/md';
import MIXTLogo from '../../../assets/images/logo.png';
import Loader from '../../../components/common/Loader';
import constants from '../../../constants/constants';
import { GLOBAL_DEFAULT } from '../../../constants/global';
import { purchaseOrderService } from '../services';
import { formatCost } from '../../../helpers/utils';

const RenderInnerHTML = (props: any) => (<span dangerouslySetInnerHTML={{ __html: props.HTML }}></span>);

const PrintPerforma: React.FC<any> = () => {
    const [state, setState] = useState<any>(null);
    const [poDetails, setPODetails] = useState<any>();
    const [purchaseOrderItems, setPurchaseOrderItems] = useState<any>();
    const [loading, setLoading] = useState<boolean>(GLOBAL_DEFAULT.LOADER_STATE);

    useEffect(() => {
        try {
            const windowName = window.name;
            const state = JSON.parse(windowName);

            setState(state);
        } catch (error) {
            console.error('Failed to parse state from window name', error);
        }
    }, []);

    const getMultiplePurchaseOrderPrintProforma = useCallback(async () => {
        if (state?.poId) {
            setLoading(true);
            try {
                const purchaseOrderIds: any = {
                    poIds: state?.poId
                };

                const response = await purchaseOrderService.getMultiplePurchaseOrderPrintProforma(purchaseOrderIds);
                const responseData = response?.data;
                const purchaseOrderItems = responseData?.map((poI: any, index: number) => {
                    const purchase_order_items = poI?.purchase_order_items?.map((poItem: any) => {
                        const poData = poItem;
                        
                        if (poItem.product_type === 'Bundle') {
                            const data = poItem?.core_products?.map((item: any) => { return { ...item, bundle_id: poItem?.id } });
                            if(data) {
                                return [poItem, ...data]
                            }
                        }

                        return poData;
                    }).flat(1).filter((item: any) => (item.status === 0 || item.status === 1));

                    if(responseData[index]) {
                        responseData[index].hasMasterBoxQty = poI?.purchase_order_items?.some((item: any) => item?.item_type === 'Master');
                    }
                    
                    return purchase_order_items;
                }) ?? [];
                
                setPODetails(responseData);
                setPurchaseOrderItems(purchaseOrderItems);
            } catch (error) {
                console.error('Failed to get purchase order details', error);
            } finally {
                setLoading(false);
            }
        }

    }, [state?.poId]);

    useEffect(() => {
        getMultiplePurchaseOrderPrintProforma();

    }, [getMultiplePurchaseOrderPrintProforma]);

    return <>
        <Loader isLoading={loading} />
        <div className='text-center hide-on-print pt-3'><Button className='custom-min-width-85' size='sm' variant="dark" onClick={() => window.print()}>Print</Button></div>
        {poDetails && poDetails.map((poDetail: any, index: number) => {
            let itemIndex: number = 0;

            return <>
                <div key={index} style={{ pageBreakBefore: 'always', lineHeight: 1.2 }}>
                    <table
                        aria-hidden="true"
                        style={{
                            fontFamily: '"Source Sans Pro", sans-serif',
                            fontSize: 14,
                            color: "#000000",
                            margin: "10px auto",
                            padding: 0,
                            width: "100%",
                            background: "#FFFFFF",
                            textAlign: "left",
                            borderCollapse: "collapse",
                            border: "1px solid #CCCCCC",
                            maxWidth: "700px",
                            wordBreak: "break-word"
                        }}
                    >
                        <tbody>
                            {/* Header */}
                            <tr>
                                <td
                                    style={{
                                        background: "#FFFFFF",
                                        borderBottom: "3px solid #005C99",
                                        padding: "15px 20px",
                                        textAlign: "center"
                                    }}
                                >
                                    <table
                                        style={{
                                            fontFamily: '"Source Sans Pro", sans-serif',
                                            fontSize: 14,
                                            margin: "0 auto",
                                            padding: 0,
                                            border: 0,
                                            textAlign: "left",
                                            borderCollapse: "collapse",
                                            width: "100%",
                                            pageBreakInside: "avoid"
                                        }}
                                    >
                                        <thead>
                                            <tr>
                                                <th style={{ width: 117, verticalAlign: "middle" }}>
                                                    <img
                                                        src={MIXTLogo}
                                                        alt="MIXT Logo"
                                                        width={117}
                                                        height={40}
                                                        style={{ border: 0, display: "block" }}
                                                    />
                                                </th>
                                                <th style={{ textAlign: "center", verticalAlign: "middle" }}>
                                                    <h1
                                                        style={{
                                                            margin: 0,
                                                            fontWeight: "bold",
                                                            fontSize: 24,
                                                            color: "#005C99"
                                                        }}
                                                    >
                                                        Purchase Order
                                                    </h1>
                                                </th>
                                                <th
                                                    style={{
                                                        width: 117,
                                                        textAlign: "right",
                                                        verticalAlign: "middle"
                                                    }}
                                                >
                                                    {poDetail?.po_number && <Barcode value={poDetail?.po_number} width={1}
                                                        height={30}
                                                        format={"CODE128"}
                                                        displayValue={true}
                                                        fontSize={14}
                                                    />}
                                                </th>
                                            </tr>
                                        </thead>
                                    </table>
                                </td>
                            </tr>
                            {/* Body Content */}
                            <tr>
                                <td style={{ padding: "20px 20px 0" }}>
                                    <table
                                        aria-hidden="true"
                                        style={{
                                            fontSize: 14,
                                            margin: "0 auto",
                                            padding: 0,
                                            border: 0,
                                            textAlign: "left",
                                            borderCollapse: "collapse",
                                            width: "100%"
                                        }}
                                    >
                                        <tbody>
                                            <tr>
                                                <td style={{ padding: "0 0 10px" }}>
                                                    <table
                                                        aria-hidden="true"
                                                        style={{
                                                            margin: 0,
                                                            padding: 0,
                                                            textAlign: "left",
                                                            borderCollapse: "collapse",
                                                            width: "100%",
                                                            borderColor: "#CCCCCC",
                                                            border: "1px solid #CCCCCC",
                                                            pageBreakInside: "avoid",
                                                            tableLayout: "fixed"
                                                        }}
                                                    >
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ padding: 10, border: "1px solid #CCCCCC", verticalAlign: "top" }}>
                                                                    <div style={{ marginBottom: 0, fontWeight: "bold" }}>
                                                                        PO No.
                                                                    </div>
                                                                    <div style={{ marginBottom: 0 }}>{poDetail?.po_number}</div>
                                                                </td>
                                                                <td style={{ padding: 10, border: "1px solid #CCCCCC", verticalAlign: "top" }}>
                                                                    <div style={{ marginBottom: 0, fontWeight: "bold" }}>
                                                                        Order Date
                                                                    </div>
                                                                    <div style={{ marginBottom: 0 }}>{poDetail?.createdAt ? moment(new Date(poDetail?.createdAt)).format(constants.datetimeFormats.dateTime) : ''}</div>
                                                                </td>
                                                                <td style={{ padding: 10, border: "1px solid #CCCCCC", verticalAlign: "top" }}>
                                                                    <div style={{ marginBottom: 0, fontWeight: "bold" }}>
                                                                        Shipping Terms
                                                                    </div>
                                                                    <div style={{ marginBottom: 0 }}>
                                                                        {poDetail?.shipping_terms?.name}
                                                                    </div>
                                                                </td>
                                                                <td style={{ padding: 10, border: "1px solid #CCCCCC", verticalAlign: "top" }}>
                                                                    <div style={{ marginBottom: 0, fontWeight: "bold" }}>
                                                                        Payment Terms
                                                                    </div>
                                                                    <div style={{ marginBottom: 0 }}>{poDetail?.payment_term?.name}</div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: 0 }}>
                                                    <table
                                                        aria-hidden="true"
                                                        style={{
                                                            margin: 0,
                                                            padding: 0,
                                                            textAlign: "left",
                                                            borderCollapse: "collapse",
                                                            width: "100%",
                                                            pageBreakInside: "avoid",
                                                            tableLayout: "fixed",
                                                            border: "1px solid #CCCCCC",
                                                            background: "#E6EFF5"
                                                        }}
                                                    >
                                                        <tbody>
                                                            <tr>
                                                                <td
                                                                    style={{
                                                                        padding: 10,
                                                                        verticalAlign: "top",
                                                                        border: "1px solid #CCCCCC"
                                                                    }}
                                                                >
                                                                    <h5
                                                                        style={{
                                                                            fontSize: 16,
                                                                            fontWeight: "bold",
                                                                            color: "#005C99",
                                                                            marginBottom: 4
                                                                        }}
                                                                    >
                                                                        Supplier Details
                                                                    </h5>
                                                                    <div
                                                                        style={{
                                                                            fontSize: 14,
                                                                            fontWeight: "bold",
                                                                            marginBottom: 2
                                                                        }}
                                                                    >
                                                                        {poDetail?.vendor?.name}
                                                                    </div>
                                                                    <div style={{ marginBottom: 6 }}>
                                                                        <RenderInnerHTML HTML={poDetail?.vendor_address} /> <br />
                                                                        {poDetail?.vendor?.email}<br />
                                                                        {poDetail?.vendor?.phone}
                                                                    </div>
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        padding: 10,
                                                                        verticalAlign: "top",
                                                                        border: "1px solid #CCCCCC"
                                                                    }}
                                                                >
                                                                    <h5
                                                                        style={{
                                                                            fontSize: 16,
                                                                            fontWeight: "bold",
                                                                            color: "#005C99",
                                                                            marginBottom: 4
                                                                        }}
                                                                    >
                                                                        Buyer (Bill to)
                                                                    </h5>
                                                                    <div
                                                                        style={{
                                                                            fontSize: 14,
                                                                            fontWeight: "bold",
                                                                            marginBottom: 2
                                                                        }}
                                                                    >
                                                                        {poDetail?.buyer_name}
                                                                    </div>
                                                                    <div style={{ marginBottom: 6 }}>
                                                                        <RenderInnerHTML HTML={poDetail?.buyer_address} />
                                                                    </div>
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        padding: 10,
                                                                        verticalAlign: "top",
                                                                        border: "1px solid #CCCCCC"
                                                                    }}
                                                                >
                                                                    <h5
                                                                        style={{
                                                                            fontSize: 16,
                                                                            fontWeight: "bold",
                                                                            color: "#005C99",
                                                                            marginBottom: 4
                                                                        }}
                                                                    >
                                                                        Consignee (Ship to)
                                                                    </h5>
                                                                    <div
                                                                        style={{
                                                                            fontSize: 14,
                                                                            fontWeight: "bold",
                                                                            marginBottom: 2
                                                                        }}
                                                                    >
                                                                        {poDetail?.warehouse?.name}
                                                                    </div>
                                                                    <div style={{ marginBottom: 6 }}>
                                                                        <RenderInnerHTML HTML={poDetail?.warehouse_address} />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: "10px 0" }}>
                                                    <table
                                                        style={{
                                                            margin: 0,
                                                            padding: 0,
                                                            textAlign: "left",
                                                            borderCollapse: "collapse",
                                                            width: "100%",
                                                            pageBreakInside: "avoid",
                                                            borderColor: "#CCCCCC",
                                                            border: "1px solid #CCCCCC"
                                                        }}
                                                    >

                                                        <thead>
                                                            <tr>
                                                                <th
                                                                    style={{
                                                                        fontWeight: "bold",
                                                                        padding: "15px 10px",
                                                                        verticalAlign: "top",
                                                                        textAlign: "center",
                                                                        width: 62,
                                                                        whiteSpace: "nowrap",
                                                                        border: "1px solid #CCCCCC",
                                                                        background: "#E6EFF5"
                                                                    }}
                                                                >
                                                                    S. No.
                                                                </th>
                                                                <th
                                                                    style={{
                                                                        fontWeight: "bold",
                                                                        padding: "15px 10px",
                                                                        verticalAlign: "top",
                                                                        width: "30%",
                                                                        whiteSpace: "nowrap",
                                                                        border: "1px solid #CCCCCC",
                                                                        background: "#E6EFF5"
                                                                    }}
                                                                >
                                                                    Vendor SKU{" "}
                                                                </th>
                                                                {poDetail?.hasMasterBoxQty && (
                                                                    <th
                                                                        style={{
                                                                            fontWeight: "bold",
                                                                            padding: "15px 10px",
                                                                            verticalAlign: "top",
                                                                            textAlign: "right",
                                                                            width: 62,
                                                                            whiteSpace: "nowrap",
                                                                            border: "1px solid #CCCCCC",
                                                                            background: "#E6EFF5"
                                                                        }}
                                                                    >
                                                                        Master Cases
                                                                    </th>
                                                                )}
                                                                <th
                                                                    style={{
                                                                        fontWeight: "bold",
                                                                        padding: "15px 10px",
                                                                        verticalAlign: "top",
                                                                        textAlign: "right",
                                                                        whiteSpace: "nowrap",
                                                                        border: "1px solid #CCCCCC",
                                                                        background: "#E6EFF5"
                                                                    }}
                                                                >
                                                                    Total Unit Qty
                                                                </th>
                                                                <th
                                                                    style={{
                                                                        fontWeight: "bold",
                                                                        padding: "15px 10px",
                                                                        verticalAlign: "top",
                                                                        textAlign: "right",
                                                                        whiteSpace: "nowrap",
                                                                        border: "1px solid #CCCCCC",
                                                                        background: "#E6EFF5"
                                                                    }}
                                                                >
                                                                    Unit Price
                                                                </th>
                                                                <th
                                                                    style={{
                                                                        fontWeight: "bold",
                                                                        padding: "15px 10px",
                                                                        verticalAlign: "top",
                                                                        textAlign: "right",
                                                                        whiteSpace: "nowrap",
                                                                        border: "1px solid #CCCCCC",
                                                                        background: "#E6EFF5"
                                                                    }}
                                                                >
                                                                    Total
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {purchaseOrderItems[index]?.map((item: any, indexPO: number) => {
                                                                itemIndex = !item?.bundle_id ? itemIndex + 1 : itemIndex;
                                                                return <tr key={index + " " +indexPO}>
                                                                    <td
                                                                        style={{
                                                                            padding: "4px 10px",
                                                                            textAlign: "center",
                                                                            fontWeight: "normal",
                                                                            border: "1px solid #CCCCCC"
                                                                        }}
                                                                    >
                                                                        {!item.bundle_id && itemIndex}
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            padding: "4px 10px",
                                                                            fontWeight: "normal",
                                                                            border: "1px solid #CCCCCC"
                                                                        }}
                                                                    >
                                                                        {item.product_type === 'Bundle'
                                                                            ? <Stack direction='horizontal' gap={1}>
                                                                                <MdInventory2 size={20} className='d-block' />
                                                                                {item?.product_vendor?.vendor_sku}
                                                                            </Stack>
                                                                            : (item?.bundle_id
                                                                                ? <Stack direction='horizontal' gap={1}>
                                                                                    {/* {SvgIcons.replyIcon} */}
                                                                                    (<b>{item.quantity}x</b>)&nbsp;
                                                                                    {item?.product_vendor?.vendor_sku}
                                                                                </Stack>
                                                                                : <Stack direction='horizontal' gap={1}>
                                                                                    {/* {SvgIcons.package2Icon} */}
                                                                                    {item?.product_vendor?.vendor_sku}
                                                                                </Stack>
                                                                            )
                                                                        }
                                                                    </td>
                                                                    {poDetail?.hasMasterBoxQty && (
                                                                        <td
                                                                            style={{
                                                                                padding: "4px 10px",
                                                                                textAlign: "right",
                                                                                fontWeight: "normal",
                                                                                border: "1px solid #CCCCCC"
                                                                            }}
                                                                        >
                                                                            {item?.item_type === 'Master' ? item?.quantity : 'N/A'}
                                                                        </td>
                                                                    )}
                                                                    <td
                                                                        style={{
                                                                            padding: "4px 10px",
                                                                            textAlign: "right",
                                                                            fontWeight: "normal",
                                                                            border: "1px solid #CCCCCC"
                                                                        }}
                                                                    >
                                                                        {item?.total_quantity}
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            padding: "4px 10px",
                                                                            textAlign: "right",
                                                                            fontWeight: "normal",
                                                                            border: "1px solid #CCCCCC"
                                                                        }}
                                                                    >
                                                                        {item.product_type !== 'Bundle' ? (item?.billed_unit_cost && `$${formatCost(item?.billed_unit_cost)}`) || `$${formatCost(item?.unit_cost) ?? '-'}` : 'N/A'}
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            padding: "4px 10px",
                                                                            textAlign: "right",
                                                                            fontWeight: "normal",
                                                                            border: "1px solid #CCCCCC"
                                                                        }}
                                                                    >
                                                                        {item.product_type !== 'Bundle' ? '$' + formatCost(item?.total_cost) : 'N/A'}
                                                                    </td>
                                                                </tr>
                                                            })}
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td
                                                                    colSpan={poDetail?.hasMasterBoxQty ? 5 : 4}
                                                                    style={{
                                                                        padding: "8px 10px",
                                                                        fontSize: 16,
                                                                        fontWeight: "bold",
                                                                        textAlign: "right",
                                                                        background: "#E6EFF5",
                                                                        border: "1px solid #CCCCCC"
                                                                    }}
                                                                >
                                                                    Total
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        padding: "8px 10px",
                                                                        fontSize: 16,
                                                                        fontWeight: "bold",
                                                                        textAlign: "right",
                                                                        background: "#E6EFF5",
                                                                        border: "1px solid #CCCCCC"
                                                                    }}
                                                                >
                                                                    ${formatCost(poDetail?.total_cost)}
                                                                </td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr hidden={!poDetail?.message_to_vendor}>
                                                <td
                                                    style={{
                                                        padding: 10,
                                                        border: "1px solid #CCCCCC",
                                                        background: "#E6EFF5",
                                                        verticalAlign: "top",
                                                        pageBreakInside: "avoid"
                                                    }}
                                                >
                                                    <strong style={{ fontWeight: "bold" }}>
                                                        Notes To Vendor:{" "}
                                                    </strong>
                                                    {poDetail?.message_to_vendor}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            {/* Footer */}
                            <tr>
                                <td style={{ padding: 20 }}>
                                    <p style={{ margin: "0 0 12px" }}>
                                        Please confirm receipt of this purchase order
                                    </p>
                                    <p style={{ margin: "0 0 12px" }}>
                                        Also, send all invoices as soon as possible via email to{" "}
                                        <a
                                            style={{ fontWeight: "normal", fontSize: 14, color: "#0D6EFD", textDecoration: "underline" }}
                                            href="mailto:bradley@mixtsolutions.com"
                                        >
                                            bradley@mixtsolutions.com
                                        </a>{" "}
                                        including itemized cost, due date, and expectation of payment. If you
                                        have any questions in regards to payment, please email or call Bradley
                                        at{" "}
                                        <a
                                            style={{ fontWeight: "normal", fontSize: 14, color: "" }}
                                            href="tel:+6144832878"
                                        >
                                            614.483.2878
                                        </a>
                                        .
                                    </p>
                                    <p style={{ margin: "0 0 12px" }}>
                                        Mixt Solutions Warehouse operates on a first come first serve basis
                                        with deliveries and pick ups. Our hours are strictly M-F, 9am-5pm.
                                    </p>
                                    <p style={{ margin: "0 0 12px", fontSize: 16 }}>
                                        <strong style={{ fontWeight: "bold" }}>Thank You,</strong>
                                        <br />
                                        Team Mixt Solutions
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </>
        })
        }
    </>;
}

export default PrintPerforma;