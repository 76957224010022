import { refreshToken } from "../services/auth.service";
import storage from "./storage";

/**
 * Extends the global `Window` interface to include the `grecaptcha` property.
 * 
 * @interface Window
 */
declare global {
    interface Window {
        grecaptcha: any;
    }
}

export const getToken = () => {

}

/**
 * 
 * @param {string} SITE_KEY
 * @returns {string} token
 */
export const getCaptchaToken = async (SITE_KEY: string): Promise<any> => {
    let token = null
    await window.grecaptcha?.execute(SITE_KEY, { action: "submit" })
        .then((res: string) => {
            token = res;
        })
    return token;
}

/**
 * Asynchronously refreshes the user's authentication token.
 *
 * @async
 * @function refreshTokenAPI
 * @returns {Promise<boolean>} A promise that resolves to true if the token is refreshed successfully, and false otherwise.
 *
 * @throws {Error} If there's an issue with the token refresh process.
 *
 * @example
 */
export const refreshTokenAPI = async () => {
    if (storage.getToken()) {
        const token = storage.getRefreshToken();
        if(token) {
            await refreshToken({ refresh_token: token }).then((response: any) => {
                storage.setToken(response?.data?.token);
                storage.setRefreshToken(response?.data?.refresh_token);
                return true;
            }).catch((error: any) => {
                return false;
            });
        }
    }
}

export const setApplicationModule = (path: string) => {
    if(path) {
        const prevModule = getApplicationModule();
        const module = path.split('/');
        let currentModule: string = '';
        if(['settings', 'user-management'].includes(module[2])) {
            currentModule = module[3];
        } else {
            currentModule = module[2];
        }
        
        if(prevModule !== currentModule) {
            storage.setData('currentModule', currentModule);
            storage.clearData('filters');
        }
    }
}

export const getApplicationModule = () => {
    return storage.getData('currentModule');
}

export const hasPermission = (permission: string) => {
    const label = storage.getData('label');
    const permissions = storage.getData('permissions');

    return label.includes('SUPER_ADMIN') || permissions.includes(permission);
}

export const setFilter = (key: string, data: any) => {
    const filters = getAppliedFilters(key);
    
    const setFilters: any = {};
    if(filters) {
        setFilters[key] = {...filters, ...data}
    } else {
        setFilters[key] = {...data};
    }
    storage.setData('filters', setFilters);
}

export const getAppliedFilters = (key: any) => {
    const filters = storage.getData('filters');
    if(filters) {
        return filters[key];
    } else {
        return null;
    }
}

//Filter the Countries and set US and CA in top of the Dropdown
export const formatCountries = (countries: any[]) => {
    return countries
        ?.map(({ name, id, iso2, iso3 }) => ({ label: name, value: id, iso2, iso3 }))
        ?.sort((a, b) => {
            if (a.label === 'United States') return -1;
            if (b.label === 'United States') return 1;
            if (a.label === 'Canada') return -1;
            if (b.label === 'Canada') return 1;

            return a.label.localeCompare(b.label);
        });
};

export const capitalize = (data: string) => {
    return data.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
}

// Function to truncate text
export const truncateText = (text: string, maxLength: number): string => {
    return text?.length > maxLength ? text?.substring(0, maxLength) + "..." : text;
};

/**
 * Formats a cost value to ensure it displays correctly:
 * - If the value has two or fewer decimal places, it shows exactly two decimal places.
 * - If the value has more than two decimal places, it remains unchanged.
 * - Handles dollar sign formatting.
 *
 * @param {string} cost - The cost value to format (including the dollar sign).
 * @returns {string} - The formatted cost as a string.
 */
export const formatCost = (cost: string): string => {
    // Convert the cost to a string if it's not already a string
    if (typeof cost !== 'string') {
        cost = String(cost);
    }

    // Determine if the input has a dollar sign
    const hasDollarSign = cost.startsWith('$');

    // Remove the dollar sign and convert to a number
    const num = Number(cost.replace(/^\$/, ''));

    // Check if the conversion to number is successful
    if (isNaN(num)) {
        return '0.00'; // or return a default value like '$0.00'
    }

    let hasNagative = false;
    let costValue;
    if (num < 0) {
        hasNagative = true;
        costValue = Math?.abs(num);
    } else {
        costValue = parseFloat(num.toString());
    }

    const formattedAmount = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 7,
    }).format(costValue);

    const amountNumber = formattedAmount.replace(/^\$/, '');

    return `${hasNagative ? '-' : ''}${hasDollarSign ? '$' : ''}${amountNumber}`;
};

export const formatWeightConversion = (weight: number, unit: string) => {
    if(unit === 'pound') {
        const totalOunces = weight * 16; // 1 lb = 16 oz
        const lbs = Math.floor(totalOunces / 16); // Extract the whole pounds
        const oz = Math.round(totalOunces % 16); // Remaining ounces
        return `${lbs} lb. ${oz} oz.`;
    }

    return `${weight} ${unit}`
}