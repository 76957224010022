import { FaRegCircle } from "react-icons/fa";
import { FiPackage } from "react-icons/fi";
import { MdOutlineDashboard, MdOutlineInventory, MdOutlineLocalShipping, MdOutlineReceiptLong, MdOutlineRequestQuote, MdOutlineSettings } from "react-icons/md";
import SvgIcons from "../../SvgIcons";

export const NavItems = [
    {
        id: 1,
        menuName: 'Dashboard',
        menuAlias: "DASHBOARD",
        menuIcon: <MdOutlineDashboard size={24} />,
        path: `/app/dashboard`,
        disabled: false,
    },
    {
        id: 2,
        menuName: 'Orders',
        menuAlias: "ORDER",
        menuIcon: <MdOutlineRequestQuote size={24} />,
        path: `/app/orders`,
        disabled: false,
    },
    {
        id: 3,
        menuName: 'Inventory',
        menuAlias: "INVENTORY",
        menuIcon: <MdOutlineInventory size={22} />,
        path: `/app/inventory`,
        disabled: false,
    },
    {
        id: 4,
        menuName: 'Purchase Orders',
        menuAlias: "PURCHASE-ORDER",
        menuIcon: <MdOutlineReceiptLong size={24} />,
        path: `/app/purchase-order`,
        disabled: false,
    },
    {
        id: 5,
        menuName: 'Shipments',
        menuAlias: "SHIPMENT",
        menuIcon: <MdOutlineLocalShipping size={24} />,
        path: `/app/shipments`,
        disabled: false,
    },
    {
        id: 6,
        menuName: 'Products',
        menuAlias: "PRODUCT",
        menuIcon: <FiPackage size={22} />,
        path: `/app/products`,
        disabled: false,
    },
    {
        id: 7,
        menuName: 'Analytics',
        menuAlias: "ANALYTICS",
        menuIcon: SvgIcons.graphIcon,
        path: `/app/analytics`,
        disabled: false,
        items: [
            /* {
                id: 91,
                menuName: 'FBA Forecasting',
                menuAlias: "FBA-FORECASTING",
                menuIcon: <FaRegCircle size={10} />,
                path: `/app/analytics/fba-forecasting`,
                disabled: true,
            },
            {
                id: 92,
                menuName: 'WFS Forecasting',
                menuAlias: "WFS-FORECASTING",
                menuIcon: <FaRegCircle size={10} />,
                path: `/app/analytics/wfs-forecasting`,
                disabled: true,
            }, */
            {
                id: 93,
                menuName: 'Brand Reporting',
                menuAlias: "BRAND-REPORTING",
                menuIcon: <FaRegCircle size={10} />,
                path: `/app/analytics/brand-reporting`,
                disabled: false,
            },
            {
                id: 94,
                menuName: 'Inventory Value',
                menuAlias: "INVENTORY-VALUE",
                menuIcon: <FaRegCircle size={10} />,
                path: `/app/analytics/inventory-value`,
                disabled: false,
            },
            {
                id: 95,
                menuName: 'Profitability',
                menuAlias: "PROFITBILITY",
                menuIcon: <FaRegCircle size={10} />,
                path: `/app/analytics/profitability`,
                disabled: false,
            },
        ]
    },
    {
        id: 8,
        menuName: 'Settings',
        menuAlias: "SETTINGS",
        menuIcon: <MdOutlineSettings size={24} />,
        items: [
            {
                id: 101,
                menuName: 'Admin Users',
                menuAlias: "USER",
                menuIcon: <FaRegCircle size={10} />,
                path: `/app/settings/admin-users`,
                disabled: false,
            },
            {
                id: 102,
                menuName: 'Roles & Rights',
                menuAlias: "ADMIN-ROLE",
                menuIcon: <FaRegCircle size={10} />,
                path: `/app/settings/roles-and-rights`,
                disabled: false,
            },
            {
                id: 103,
                menuName: 'Warehouses',
                menuAlias: "WAREHOUSE",
                menuIcon: <FaRegCircle size={10} />,
                path: `/app/settings/warehouse-management`,
                disabled: false,
            },
            {
                id: 104,
                menuName: 'Sales Channels',
                menuAlias: "SALES-CHANNEL",
                menuIcon: <FaRegCircle size={10} />,
                path: `/app/settings/sales-channels`,
                disabled: false,
            },
            {
                id: 105,
                menuName: 'Shipping Providers',
                menuAlias: "SHIPPING-PROVIDER",
                menuIcon: <FaRegCircle size={10} />,
                path: `/app/settings/shipping-providers`,
                disabled: false,
            },
            {
                id: 106,
                menuName: 'Vendors',
                menuAlias: "VENDOR",
                menuIcon: <FaRegCircle size={10} />,
                path: `/app/settings/vendors`,
                disabled: false,
            },
            {
                id: 107,
                menuName: 'Orderbots',
                menuAlias: "ORDERBOT",
                menuIcon: <FaRegCircle size={10} />,
                path: `/app/settings/orderbots`,
                disabled: false,
            },
            {
                id: 108,
                menuName: 'Shipping Terms',
                menuAlias: "SHIPPING-TERM",
                menuIcon: <FaRegCircle size={10} />,
                path: `/app/settings/shipping-terms`,
                disabled: false,
            },
            {
                id: 109,
                menuName: 'Payment Terms',
                menuAlias: "PAYMENT-TERM",
                menuIcon: <FaRegCircle size={10} />,
                path: `/app/settings/payment-terms`,
                disabled: false,
            },
            {
                id: 110,
                menuName: 'Brand Management',
                menuAlias: "BRAND",
                menuIcon: <FaRegCircle size={10} />,
                path: `/app/settings/brands-management`,
                disabled: false,
            },
        ],
        disabled: false,
    }
]