import { CODE_2FA_MIN_LENGTH, PASSWORD_MIN_CHAR } from ".";

export const EMAIL_REQUIRED = "Email address is required.";
export const INVALID_EMAIL = "Invalid email address";
export const REQUIRED = "Required";
export const PASSWORD_REQUIRED = "Password is required";
export const PASSWORD_MUST_MATCH = "Confirm Password must match with the password";
export const MIN_CHAR_LENGTH = `Please enter min ${PASSWORD_MIN_CHAR} char`;
export const CODE_REQUIRED_2FA = "2fa code is required";
export const ROLE_IS_REQUIRED = "Role is required";

export const INVALID_PHONE = "This is not a valid number."
export const PHONE_REQUIRED = "Phone number is required."

export const FIRST_NAME_REQUIRED = "First name is required."
export const LAST_NAME_REQUIRED = "Last name is required."

export const CONFIRM_PASSWORD_REQUIRED = "Confirm Password is required";

export const CODE_2FA_MIN_LENGTH_MSG = `Please enter exact ${CODE_2FA_MIN_LENGTH} digits`;

export const CODE_2FA_DIGIT_ONLY_MSG = `Code must be digits only`;

export const IMAGE_FILE_TYPES = "Please select a valid image (png, jpg, or jpeg).";
export const IMAGE_FILE_SIZE_LIMIT = "Image size exceeds 1MB limit.";

export const VALIDATION_MESSAGES = {
	EMAIL_REQUIRED: "Email address is required.",
	VALID_EMAIL: "Please enter a valid email address.",
	PASSWORD_REQUIRED: "Password is required.",
	LOGIN_SUCCESS: "Login successful!",
	PASSWORD_MUST_MATCH: "Confirm New Password must match with the new password.",
	CONFIRM_PASSWORD_REQUIRED: "Confirm New Password is required.",
	VALID_PASSWORD: `Password must be 8-15 characters long and include at least one uppercase letter, one lowercase letter, one digit, and one special character (!, @, #, $, or %).`,
	INVALID_LOGIN_PASSWORD: "Invalid Password.",
	INVALID_CURRENT_PASSWORD: "Invalid Current Password.",
	CURRENT_PASSWORD_REQUIRED: "Current Password is required.",
	NEW_PASSWORD_REQUIRED: "New Password is required.",

	CHAR_MAX_10: "Can exceed upto maximum 10 characters.",
	CHAR_MAX_25: "Can exceed upto maximum 25 characters.",
	CHAR_MAX_50: "Can exceed upto maximum 50 characters.",

	NAME_REQUIRED: "Name is required.",
	INVALID_NAME_FIELDS: "Maximum of 255 characters allowed.",
	INVALID_NAME_FIELDS_PRODUCT: "Maximum of 255 characters allowed.",
	FIRST_NAME_REQUIRED: "First name is required.",
	LAST_NAME_REQUIRED: "Last name is required.",
	ROLE_REQUIRED: "Role is required.",
	ACCESS_ROLE_REQUIRED: "Access role is required.",
	DEPARTMENT_REQUIRED: "Department is required.",
	ONLY_NUMBERS: "Only numeric value is allow in phone no.",

	COMMON: {
		ALLOWED_IMAGES_FILE_TYPES: "Only JPEG, JPG, GIF and PNG file types are allowed.",
		ALLOWED_FILE_SIZE_1MB: "File size exceeds the limit of 1MB.",
		ALLOWED_FILE_SIZE_5MB: "File size exceeds the limit of 5MB.",
		ZIP_CODE: "Only alphanumeric characters, spaces, or hyphens are allowed, with a length between 4 and 10 characters.",
		// Older Message: "Only alphanumeric characters allowed, with a length between 5 and 10 characters.",
		PHONE: "Only numeric values allowed, with a length between 10 and 15 digits, no decimals."
	},

	ADMIN_USERS: {
		NAME_REQUIRED: "Name is required.",
		ROLE_REQUIRED: "Role is required.",
		PASSWORD_REQUIRED: "Password is required.",
		PASSWORD_MUST_MATCH: "Confirm Password must match with the password.",
		CONFIRM_PASSWORD_REQUIRED: "Confirm Password is required.",
		INVALID_NAME_FIELDS: "Maximum of 255 characters allowed.",
		VALID_PASSWORD: `Password must be 8-15 characters long and include at least one uppercase letter, one lowercase letter, one digit, and one special character (!, @, #, $, or %).`,
	},

	ORDER: {
		REQUIRED: {
			ADDRESS: "Address is required.",
			CITY: "City is required.",
			COUNTRY: "Country is required.",
			NAME: "Name is required.",
			STATE: "State is required.",
			TYPE: "Type is required",
			ZIPCODE: "Zip Code is required.",
			ORDERNUMBER: "Order Number is required.",
			WAREHOUSE: "Source Warehouse is required.",
			ORDERDATE: "Order Date is required.",
			AMOUNTPAID: "Amount Paid is required.",
			ASSIGN_TO: "Assign To is required.",
			REASON: "Reason for Hold is required.",
			DATE: "Date is required.",
			NOTES: "Notes is required.",
			RECIPIENT: "Recipient is required.",
			PACKAGECONTENTS: "Package Content is required.",
			NONDELIVERYINSTRUCTIONS: "Non Delivery Instructions is required.",
			HEIGHT: "Height is required.",
			WIDTH: "Width is required.",
			LENGTH: "Length is required.",
			WEIGHTOZ: "Weight(oz) is required.",
		},
		MAX: {
			CHAR_10: "Can exceed upto maximum 10 characters.",
			CHAR_15: "Can exceed upto maximum 15 characters.",
			CHAR_25: "Can exceed upto maximum 25 characters.",
			CHAR_50: "Maximum 50 characters allowed.",
			CHAR_255: "Can exceed upto maximum 255 characters.",
		},
		MIN: {
			CHAR_5: "Please enter min 5 char.",
			CHAR_10: "Please enter min 10 char.",
		},
		UP_TO_TWO_DECIMALS: "Please enter only numbers with only up to 2 decimals and maximum 5 digits.",
		UP_TO_5_DIGITS: "Please enter numeric values only, up to a maximum of 5 digits.",
		INTERNAL_NOTES: "Maximum 2000 characters allowed.",
		ORDER_NUMBER_REGEX: "Maximum 50 characters allowed.",
		QUANTITY_UNITS: "Only numeric values allowed, up to 10 digits with no decimals.",
		GREATER_THAN_ZERO: "Quantity should be greater than zero.",
		UNIT_COST: "Only numbers allowed, up to 7 digits with a maximum of 7 decimal places.",
		NOTES: "Maximum 5000 characters allowed.",
	},

	ROLES: {
		ROLE_REQUIRED: "Role is required.",
		ROLE_VALIDATION: "Role must be minimum 3 and maximum 30 alphabets and no white spaces are allowed.",
		ROLE_DESC_REQUIRED: "Role Description is required.",
		ROLE_PERMISSIONS: "Please select rights.",
		PERMISSIONS_VALIDATION: `Please select at least one right.`
	},

	SALES_CHANNEL: {
		REQUIRED: {

		},
		MATCHES: {
			SELLERID: "Seller ID must be alphanumeric."
		}
	},

	WAREHOUSE: {
		REQUIRED: {
			ADDRESS: "Address is required.",
			CITY: "City is required.",
			COUNTRY: "Country is required.",
			NAME: "Warehouse Name is required.",
			STATE: "State is required.",
			TYPE: "Type is required.",
			ZIPCODE: "Zip Code is required.",
			PHONE: "Phone is required."
		},
		MAX: {
			CHAR_10: "Can exceed upto maximum 10 characters.",
			CHAR_15: "Can exceed upto maximum 15 characters.",
			CHAR_25: "Can exceed upto maximum 25 characters.",
			CHAR_50: "Can exceed upto maximum 50 characters.",
			CHAR_255: "Can exceed upto maximum 255 characters.",
		},
		MIN: {
			CHAR_5: "Please enter min 5 char.",
			CHAR_10: "Please enter min 10 char.",
		}
	},

	WAREHOUSE_LOCATION: {
		REQUIRED: {
			NAME: "Location name is required."
		},
		MAX: {
			CHAR_255: "Can exceed upto maximum 255 characters."
		}
	},

	VENDOR: {
		REQUIRED: {
			ADDRESS: "Address is required.",
			CITY: "City is required.",
			COUNTRY: "Country is required.",
			NAME: "Vendor Name is required.",
			STATE: "State is required.",
			ZIPCODE: "Zip Code is required.",
			PHONE: "Primary Contact Phone is required.",
			PAYMENT_TERM: "Payment Term is required.",
			SHIPPING_TERM: "Shipping Term is required.",
			PO_EMAIL: "PO Email is required.",
			EMAIL: "Primary Contact Email is required."
		},
		VALID_EMAIL: "Please enter valid primary contact email.",
		ONLY_NUMBERS: "Only numeric value is allowed in primary contact phone.",
		MAX: {
			CHAR_10: "Can exceed upto maximum 10 characters.",
			CHAR_15: "Can exceed upto maximum 15 characters.",
			CHAR_25: "Can exceed upto maximum 25 characters.",
			CHAR_50: "Can exceed upto maximum 50 characters.",
			CHAR_255: "Can exceed upto maximum 255 characters.",
		},
		MIN: {
			CHAR_5: "Please enter min 5 char.",
			CHAR_10: "Please enter min 10 char.",
		},
		VALID_WEBSITE: "Invalid URL."
	},

	PRODUCT: {
		REQUIRED: {
			MASTER_SKU: "Master SKU is required",
			NAME: "Product Name is required.",
			BRAND: "Brand is required.",
			VENDOR_SKU: "Vendor SKU is required.",
			LISTING_SKU: "Listing SKU is required.",
			LATENCY: "Please enter the fulfillment latency days."
		},
		SKU: "Maximum of 255 characters allowed.",
		UP_TO_ONE_DECIMAL: "Please enter numbers only, with a maximum of 5 digits and up to one decimal place.",
		UP_TO_TWO_DECIMALS: "Please enter only numbers with only up to 2 decimals and maximum 5 digits.",
		DESCRIPTION: "Maximum 2000 characters allowed.",
		SIZE: "Images with only up to 5 MB are allowed.",
		ALLOWED_IMAGES: "Only JPEG,PNG,JPG and GIF are allowed.",
		UP_TO_5_DIGITS: "Please enter numeric values only, up to a maximum of 5 digits.",
		UP_TO_5_POSTIVE_DIGITS: "Please enter numeric values only, up to a maximum of 5 digits.",
		INAVLID_DAYS: "Only numeric values are allowed, with a maximum of 365 days.",
		MAX: {
			CHAR_10: "Can exceed upto maximum 10 characters.",
			CHAR_15: "Can exceed upto maximum 15 characters.",
			CHAR_25: "Can exceed upto maximum 25 characters.",
			CHAR_50: "Can exceed upto maximum 50 characters.",
			CHAR_255: "Can exceed upto maximum 255 characters.",
		},
		UNIT_COST: "Only numbers allowed, up to 7 digits with a maximum of 7 decimal places.",
	},

	PURCHASE_ORDER: {
		REQUIRED: {
			VENDOR: "Vendor is required",
			DESTINATION: "Destination is required.",
			SHIPPING_TERM: "Shipping Term is required.",
			PAYMENT_TERM: "Payment Term is required."
		},
		MESSAGE: "Maximum 2000 characters allowed.",
		NOTES: "Maximum 2000 characters allowed.",
		RECEIVE_PO: {
			REQUIRED: {
				DEILVERED: "Delivered is required.",
				RECEIVED: "Received is required.",
				BILLED_ON: "Billed on is required.",
				BEST_BEFORE: "Best before is required.",
				BILLED_UNIT_COST: "Billed unit cost is required.",
				LANDED_UNIT_COST: "Landed unit cost is required.",
				ACCEPT: "Accept is required.",
				REJECT: "Reject is required.",
				WAREHOUSE_LOCATION: "Warehouse receiving location is required.",
				ACCEPT_OR_REJECT: "Either Accept or Reject is required.",
				REJECTION_REASON: "Reason for rejection is required."
			},
			ITEM_MEMO: "Maximum of 255 characters allowed.",
			QUANTITY_UNITS: "Only numeric values allowed, up to 10 digits with no decimals.",
			UNIT_COST: "Only numbers allowed, up to 7 digits with a maximum of 7 decimal places.",
		}
	},

	INVENTORY: {
		REQUIRED: {
			LOCATION: "Location is required.",
			STOCK_ON_HAND: "On Hand is required.",
			PRICE: "Price is required.",
			WAREHOUSE: "Please select the warehouse."
		},
		NOTES: "Maximum 2000 characters allowed.",
		UNIT_COST: "Only numbers allowed, up to 7 digits with a maximum of 7 decimal places."
	},

	ORDER_BOTS: {
		REQUIRED: {
			NAME: "Name is required.",
			GROUP: "Group is required",
			WAREHOUSE: "Warehouse is required.",
			PRODUCT: "Product is required.",
			SALES_CHANNEL: "Sales Channel is required.",
			NOTES: "Notes is required.",
			UNIT_COST: "Unit Price is required.",
			QUANTITY: "Quantity is required.",
			ACTION_FILTER: "Action is required.",
			FILTER: "Filter is required.",
			OPERATOR: "Operator is required.",
			VALUE: "It is required.",
			PROVIDER: "Provider is required.",
			SERVICE: "Service is required.",
			USER: "User is required.",
			HEIGHT: "Height is required.",
			WIDTH: "Width is required.",
			LENGTH: "Length is required."
		},
		GROUP_VALIDATION: "Can allow only positive numbers from 1 to 9.",
		NAME_VALIDATION: "Maximum of 255 characters allowed.",
		QUANTITY_UNITS: "Only numeric values allowed, up to 10 digits with no decimals.",
		UNIT_COST: "Only numbers allowed, up to 7 digits with a maximum of 7 decimal places.",
		NOTES: "Maximum of 255 characters allowed.",
		SKU: "Maximum of 255 characters allowed."
	},
	SHIPMENT: {
		REQUIRED: {
			CARRIER_ID: "Carrier Name is required.",
			SHIPMENT_DATE: "Shipment Date is required.",
		}
	}

}