import moment from 'moment';
import React, { FC } from 'react'
import constants from '../../../constants/constants';
import { formatCost, truncateText } from '../../../helpers/utils';

interface IPrintPackaginSlipFitCrunch {
    key: number,
    orderDetails: any,
}

const PrintPackaginSlipFitCrunch: FC<IPrintPackaginSlipFitCrunch> = ({
    key,
    orderDetails
}) => {
    const RenderInnerHTML = (props: any) => (<span dangerouslySetInnerHTML={{ __html: props.HTML }}></span>);
    
    return (
            <div key={key} style={{ pageBreakBefore: 'always', lineHeight: 1.2 }}>
                <div style={{ width: '576px', maxWidth: '100%', margin: '10px auto', padding: '0', wordBreak: 'break-word', pageBreakInside: 'avoid' }}>
                    <div style={{ backgroundColor: '#000', color: '#fff', textAlign: 'center', padding: '4px 0', fontSize: '18px', fontWeight: 'bold' }}>
                        Packing Slip
                    </div>
                    <div style={{ margin: '8px 0' }}>
                        <p style={{ margin: '4px 0', fontSize: '12px' }}>Chef Robert Irvine's FITCRUNCH</p>
                        <p style={{ margin: '4px 0', fontSize: '12px' }}>Shop@FitCrunch.com</p>
                        <p style={{ margin: '4px 0', fontSize: '14px' }}>(844-850-5564)</p>
                    </div>
                    <div style={{ margin: '8px 0' }}>
                        <table style={{ width: '100%', borderCollapse: 'collapse', tableLayout: "fixed"}} aria-hidden={true}>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '0 4px 0 0', verticalAlign: 'top' }}>
                                        <p style={{ margin: '4px 0', fontSize: '12px' }}>Name: {orderDetails?.recipient?.name}</p>
                                        <p style={{ margin: '8px 0 4px 0', fontSize: '12px' }}>Ship To: <RenderInnerHTML HTML={orderDetails?.recipient?.address + ', ' + (orderDetails?.recipient?.address_2 ? orderDetails?.recipient?.address_2 + ', ' : '') + '<br />' + orderDetails?.recipient?.city + ', ' + orderDetails?.recipient?.state + ', ' + orderDetails?.recipient?.country + ', ' + orderDetails?.recipient?.zip_code} /></p>
                                        <p style={{ margin: '8px 0 4px 0', fontSize: '12px' }}>Phone: {orderDetails?.recipient?.phone}</p>
                                    </td>
                                    <td style={{ padding: '0 0 0 4px', verticalAlign: 'top' }}>
                                        <p style={{ margin: '4px 0', fontSize: '12px' }}>Order #: {orderDetails?.order_number}</p>
                                        {orderDetails?.order_date &&
                                            <p style={{ margin: '8px 0 4px 0', fontSize: '12px' }}>Date: {orderDetails?.order_date ? moment(new Date(orderDetails?.order_date)).format(constants.datetimeFormats.date) : ''}</p>
                                        }
                                        {orderDetails?.shipment_date && orderDetails?.status === 4 &&
                                            <p style={{ margin: '8px 0 4px 0', fontSize: '12px' }}>Ship Date: {orderDetails?.shipment_date ? moment(new Date(orderDetails?.shipment_date)).format(constants.datetimeFormats.date) : ''}</p>
                                        }
                                        <p style={{ margin: '8px 0 4px 0', fontSize: '12px' }}>Shipping: Standard (4-8 business days)</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style={{ margin: '8px 0' }}>
                        <p style={{ margin: '4px 0', fontSize: '12px' }}>
                        {orderDetails?.sales_channel_id === 46 && 'Thank you for your purchase through Costco Next!' } If you need assistance with your order or need to arrange a return, please contact Chef Robert Irvine's FITCRUNCH Customer Service directly at:</p>
                        <p style={{ margin: '8px 0 4px 0', fontSize: '12px' }}>Shop@FitCrunch.com</p>
                        <p style={{ margin: '8px 0 4px 0', fontSize: '16px' }}>(844-850-5564)</p>
                    </div>
                    <div style={{ width: '100%' }}>
                        <div style={{ margin: '8px 0' }}>
                            <table style={{ width: '100%', borderCollapse: 'separate', borderSpacing: '2px' }}>
                                <thead>
                                    <tr>
                                        <th style={{ borderBottom: '1px solid #000', padding: '4px', textAlign: 'left', backgroundColor: '#000', color: '#fff', fontSize: '12px', width: '50%' }}>Item Title</th>
                                        <th style={{ borderBottom: '1px solid #000', padding: '4px', textAlign: 'right', backgroundColor: '#000', color: '#fff', fontSize: '12px', whiteSpace: 'nowrap' }}>Qty</th>
                                        <th style={{ borderBottom: '1px solid #000', padding: '4px', textAlign: 'right', backgroundColor: '#000', color: '#fff', fontSize: '12px' }}>Unit Price</th>
                                        <th style={{ borderBottom: '1px solid #000', padding: '4px', textAlign: 'right', backgroundColor: '#000', color: '#fff', fontSize: '12px' }}>Tax Paid</th>
                                        <th style={{ borderBottom: '1px solid #000', padding: '4px', textAlign: 'right', backgroundColor: '#000', color: '#fff', fontSize: '12px' }}>Final Cost</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderDetails?.order_item_info.map((item: any, index: number) => {
                                        return (
                                            <tr key={index}>
                                                <td style={{ borderBottom: '1px solid #000', padding: '4px', textAlign: 'left', fontSize: '12px' }}>{item?.product_name ? truncateText(item?.product_name, 100) : "-"}</td>
                                                <td style={{ borderBottom: '1px solid #000', padding: '4px', textAlign: 'right', fontSize: '12px' }}>{item?.quantity}</td>
                                                <td style={{ borderBottom: '1px solid #000', padding: '4px', textAlign: 'right', fontSize: '12px' }}>{formatCost(item?.unit_cost)}</td>
                                                <td style={{ borderBottom: '1px solid #000', padding: '4px', textAlign: 'right', fontSize: '12px' }}>{formatCost('0.00')}</td>
                                                <td style={{ borderBottom: '1px solid #000', padding: '4px', textAlign: 'right', fontSize: '12px' }}>{formatCost(item?.total_cost)}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div style={{ margin: '8px 0', textAlign: 'right' }}>
                            {orderDetails?.sales_channel_id &&
                                <p style={{ margin: '0', fontSize: '14px', textAlign: 'left' }}>{orderDetails?.salesChannel?.name}</p>
                            }
                            <table style={{ width: 'auto', borderCollapse: 'collapse', marginLeft: 'auto' }} aria-hidden={true}>
                                <tbody>
                                    <tr>
                                        <td style={{ padding: '2px 4px', textAlign: 'right', fontSize: '12px' }}>
                                            <strong>SubTotal:</strong>
                                        </td>
                                        <td style={{ padding: '2px 4px', textAlign: 'right', fontSize: '12px' }}>
                                            ${formatCost(orderDetails?.order_total)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: '2px 4px', textAlign: 'right', fontSize: '12px' }}>
                                            <strong>Tax:</strong>
                                        </td>
                                        <td style={{ padding: '2px 4px', textAlign: 'right', fontSize: '12px' }}>
                                            {formatCost('$0.00')}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: '2px 4px', textAlign: 'right', fontSize: '12px' }}>
                                            <strong>Shipping Cost:</strong>
                                        </td>
                                        <td style={{ padding: '2px 4px', textAlign: 'right', fontSize: '12px' }}>
                                            {formatCost('$0.00')}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: '8px 4px', textAlign: 'right', fontSize: '12px' }}>
                                            <strong>Total:</strong>
                                        </td>
                                        <td style={{ padding: '8px 4px', textAlign: 'right', fontSize: '12px' }}>
                                            ${formatCost(orderDetails?.order_total)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default PrintPackaginSlipFitCrunch